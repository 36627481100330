import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const PrivacyPolicy = () => {
  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <h2
              className="text-center text-danger mb-5"
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "0.5px",
              }}
            >
              Privacy Policy
            </h2>

            <section className="mb-4 ">
              <h4 className="mb-2">Introduction</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  This Privacy Policy applies to fusiongaming.in. By using our
                  website, you agree to this Privacy Policy.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Information We Collect</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Contact Information:</b> We collect your name, email,
                  mobile number, phone number, street, city, state, pincode,
                  country, and IP address.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Payment and Billing Information:</b> We collect your
                  billing name, billing address, and payment method when you buy
                  a product. Credit card information is processed by our payment
                  partner PhonePe.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Information You Post:</b> We collect information you post
                  on our website or on a third-party social media site belonging
                  to fusiongaming.in.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Demographic Information:</b> We may collect demographic
                  information about you, including your preferences and survey
                  responses.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Other Information:</b> We collect information about your IP
                  address, browser, and usage patterns on our website.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Information Collection Methods</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Direct Collection:</b> We collect information directly from
                  you when you register or buy a product.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Passive Collection:</b> We use tracking tools like Google
                  Analytics and browser cookies for collecting information about
                  your usage of our website.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Third-Party Information:</b> If you use an integrated
                  social media feature on our websites, the third-party social
                  media site will give us certain information about you.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Use of Your Personal Information</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Contacting You:</b> We use the information to contact you
                  for purchase confirmations or promotions.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Responding to Requests:</b> We use your information to
                  respond to your requests or questions.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Improving Our Services:</b> We use your information to
                  customize your experience and improve our products and
                  services.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Security Purposes:</b> We use information to protect our
                  company, customers, and websites.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Marketing Purposes:</b> We might send you information about
                  promotions, offers, and new features.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Transactional Communications:</b> We send emails or SMS
                  about your account or purchases.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Sharing of Information</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Service Providers:</b> We share information with third
                  parties who perform services on our behalf.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Business Partners:</b> We may share information with
                  business partners, such as event sponsors or venue operators.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Legal Compliance:</b> We share information to comply with
                  the law or respond to legal requests.
                </li>
                <li className="ms-4 mb-3 text-white rounded">
                  <b>Business Transfers:</b> We share information with any
                  successor to all or part of our business.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Email Opt-Out</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  You can opt out of receiving our marketing emails by
                  contacting us at{" "}
                  <a
                    href="mailto:future.retail20@gmail.com"
                    className="link-danger"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    future.retail20@gmail.com
                  </a>
                  . It may take about ten days to process your request. We will
                  still send you transactional messages about your purchases.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Third-Party Sites</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  This policy does not apply to third-party websites linked from
                  our site. Read the privacy policy of other websites carefully.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Grievance Officer</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  In accordance with the Information Technology Act 2000 and the
                  rules made thereunder, the contact details of the Grievance
                  Officer are:
                  <p style={{ fontSize: "1.1rem", lineHeight: "1.8" }}>
                    <b>Mr. Sam Daniel</b>
                    <br />
                    The Marina Mall, OMR, Chennai - 603 103
                    <br />
                    Phone: +91 63699 33507
                    <br />
                    Email:{" "}
                    <a
                      href="mailto:future.retail20@gmail.com"
                      className="link-danger"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      future.retail20@gmail.com
                    </a>
                  </p>
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Updates to This Policy</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  This Privacy Policy was last updated on November 01, 2024.
                  Please check our site periodically for updates.
                </li>
              </ul>
            </section>

            <section className="mb-4">
              <h4 className="mb-2">Jurisdiction</h4>
              <ul
                className="list-unstyled"
                style={{ fontSize: "1.1rem", lineHeight: "1.8" }}
              >
                <li className="ms-4 mb-3 text-white rounded">
                  Disputes arising under this policy shall be governed by the
                  laws of India and subject to the jurisdiction of the courts of
                  Chennai, Tamil Nadu.
                </li>
              </ul>
            </section>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
