import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ReturnsPolicy = () => {
  return (
    <div className="bg-black py-5">
      <Container>
        <Row className="justify-content-center text-white">
          <Col md={8} className="text-left">
            <h2
              className="text-center text-danger mb-4"
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Returns Policy
            </h2>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              We offer{" "}
              <strong className="text-danger">refunds or exchanges</strong>{" "}
              within the first <strong className="text-danger">7 days</strong>{" "}
              from the date of purchase. If 7 days have passed since your
              purchase, no returns, exchanges, or refunds will be processed.
            </p>

            <p style={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}>
              To qualify for a return or exchange, the following conditions must
              be met:
            </p>
            <ul
              style={{
                fontSize: "1.1rem",
                paddingLeft: "1.5rem",
                marginBottom: "1.5rem",
              }}
            >
              <li>
                The item must be <strong className="text-danger">unused</strong>{" "}
                and in the same condition as when you received it.
              </li>
              <li>
                It must be returned in its{" "}
                <strong className="text-danger">original packaging</strong>.
              </li>
              <li>
                Items purchased{" "}
                <strong className="text-danger"> on sale </strong> may not
                qualify for a return or exchange.
              </li>
            </ul>

            <p style={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}>
              We will replace items only if they are{" "}
              <strong>defective or damaged</strong> based on an exchange
              request.
            </p>

            <p style={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}>
              To request a return or exchange, email us at:{" "}
              <a
                href="mailto:future.retail20@gmail.com"
                className="text-danger"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>future.retail20@gmail.com</strong>
              </a>
            </p>

            <p style={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}>
              Some product categories may be{" "}
              <strong className="text-danger">
                {" "}
                exempt from returns or refunds
              </strong>
              . These items will be clearly identified at the time of purchase.
            </p>

            <p style={{ fontSize: "1.1rem", marginBottom: "1.5rem" }}>
              Once your return or exchange is received and inspected, we will
              notify you via email. If approved after our quality check, we will
              process the return or exchange as per our policy.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ReturnsPolicy;
