import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/config");
  };
  return (
    <div className="hero-section position-relative">
      <video
        className="hero-video"
        src="/video/FG_Final.mp4"
        autoPlay
        loop
        muted
        playsInline
      ></video>
      <div className="hero-content-bottom">
        <Container className="text-center text-white">
          <Row>
            <Col>
              <h1>Welcome To Fusion Gaming</h1>
              <p>You Dream, We Build</p>
              <Button variant="danger" size="lg" onClick={handleClick}>
                Build Yours Now
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
