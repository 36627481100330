import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { url } from "../../config";
import { validEmail, validName, validPhone } from "../Regex/Regex";

const Careers = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError("");
    if (!formData.name || !validName.test(formData.name)) {
      setError("Please enter a valid name!");
      setShowAlert(true);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      return;
    }
    if (!formData.phone || !validPhone.test(formData.phone)) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      return;
    }
    if (!formData.description) {
      setError("Please enter a brief description!");
      setShowAlert(true);
      return;
    }
    if (formData.description.length > 300) {
      setError("Please keep the description under 300 characters!");
      setShowAlert(true);
      return;
    }
    sendDetails(formData);
  };

  async function sendDetails(data) {
    try {
      await axios.post(url + "/careers", { data });
      setError("Your application has been submitted successfully!");
      setShowAlert(true);
      setFormData({
        name: "",
        email: "",
        phone: "",
        description: "",
      });
      setTimeout(() => {
        setShowAlert(false);
        navigate("/");
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              Careers
            </h1>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  className="bg-light"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Label>Phone number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                  className="bg-light"
                  value={formData.phone}
                  maxLength={10}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formCoverLetter" className="mb-3">
                <Form.Label>
                  Description{" "}
                  <span className="text-secondary">
                    ({formData.description.length}/300)
                  </span>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  placeholder="Explain how you could make a differnece to our organization"
                  name="description"
                  className="bg-light"
                  value={formData.description}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button variant="danger" size="lg" onClick={handleSubmit}>
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Careers;
