import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
  Form,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { validEmail, validName, validPhone } from "../Regex/Regex";
import { BiSupport } from "react-icons/bi";

const EditProfile = () => {
  const navigate = useNavigate();
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");

  const [formData, setFormData] = useState({
    firstName: user.userInfo[0].firstname,
    lastName: user.userInfo[0].lastname,
    email: user.userInfo[0].email,
    phone: user.userInfo[0].number,
    gender: user.userInfo[0].gender,
    dob: user.userInfo[0].dob,
    gst: user.userInfo[0].gst,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!formData.firstName || !validName.test(formData.firstName)) {
      setError("Please enter a valid first name!");
      setShowAlert(true);
      return;
    }
    if (!formData.lastName || !validName.test(formData.lastName)) {
      setError("Please enter a valid last name!");
      setShowAlert(true);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      return;
    }
    if (
      !(formData.phone === null || formData.phone === "") &&
      !validPhone.test(formData.phone)
    ) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      return;
    }
    updateUser();
  };

  async function updateUser() {
    var veri = 1;
    if (user.userInfo[0].number !== formData.phone) {
      veri = 0;
    }
    try {
      const res = await axios.post(
        url + "/updateuser",
        {
          userId: user.userInfo[0].id,
          firstName: formData.firstName,
          lastName: formData.lastName,
          number: formData.phone === null ? "" : formData.phone,
          email: formData.email,
          dob: formData.dob === null ? "" : formData.dob,
          gender: formData.gender === null ? "" : formData.gender,
          gst: formData.gst === null ? "" : formData.gst,
          verified: veri === 1 ? user.userInfo[0].verified : veri,
        },
        { withCredentials: true }
      );
      setUser({ ...user, userInfo: res.data });
      setError("User Updated Successfully!!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        navigate("/profile");
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  }

  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const handleLogout = async (e) => {
    setAuthContext(null);
    setUser(null);
    try {
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/");
  };

  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {user.userInfo[0].firstname} <br />
              {user.userInfo[0].email}
            </Card.Text>
          </Card.Body>
        </Card>

        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink as={Link} to="/profile" className="link-danger">
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/orders" className="link-danger">
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/addresses" className="link-danger">
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/coupons" className="link-danger">
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/tickets" className="link-danger">
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {user.userInfo[0].firstname} <br />
            {user.userInfo[0].email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );

  return (
    <Container className="mt-5">
      <Row className="mx-2">
        <Col xs={12} className="d-block d-lg-none">
          <Button
            variant="dark"
            onClick={handleToggleOffcanvas}
            aria-controls="offcanvas-sidebar"
            aria-expanded={showOffcanvas}
            className="mb-3"
          >
            <FaBars /> Menu
          </Button>
        </Col>
        <Col
          xs={12}
          className="d-block d-lg-none border border-dark rounded p-3"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Card border="danger" style={{ backgroundColor: "#151515" }}>
            <Card.Body>
              <Card.Title className="text-danger mb-3">Edit Profile</Card.Title>
              <Form onSubmit={handleSubmit} className="p-4  rounded shadow-sm">
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formFirstName">
                    <Form.Label className="text-danger">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      className="bg-light"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formLastName">
                    <Form.Label className="text-danger">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name"
                      name="lastName"
                      className="bg-light"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formDob">
                    <Form.Label className="text-danger">
                      Date Of Birth
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter your DOB (YYYY-MM-DD)"
                      name="dob"
                      className="bg-light"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGender">
                    <Form.Label className="text-danger">Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formPhone">
                    <Form.Label className="text-danger">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      autoComplete="off"
                      placeholder="Enter your phone number"
                      name="phone"
                      className="bg-light"
                      maxLength={10}
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGst">
                    <Form.Label className="text-danger">GST Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your GST Number"
                      name="gst"
                      className="bg-light"
                      value={formData.gst}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                {showAlert && (
                  <Row>
                    <Col className="h5 mt-3 text-danger">{error}</Col>
                  </Row>
                )}
                <Button
                  variant="danger"
                  className="mt-4"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mx-2">
        <Col
          lg={3}
          className="d-none d-lg-block border border-dark rounded p-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Sidebar />
        </Col>
        <Col
          lg={8}
          className="d-none d-lg-block border border-dark rounded p-3 mx-4"
          style={{ backgroundColor: "#151515", minHeight: "55vh" }}
        >
          <Card border="danger" style={{ backgroundColor: "#151515" }}>
            <Card.Body>
              <Card.Title className="text-danger mb-3">Edit Profile</Card.Title>
              <Form onSubmit={handleSubmit} className="p-4  rounded shadow-sm">
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formFirstName">
                    <Form.Label className="text-danger">First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your first name"
                      name="firstName"
                      className="bg-light"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formLastName">
                    <Form.Label className="text-danger">Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your last name"
                      name="lastName"
                      className="bg-light"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formDob">
                    <Form.Label className="text-danger">
                      Date Of Birth
                    </Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter your DOB (YYYY-MM-DD)"
                      name="dob"
                      className="bg-light"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGender">
                    <Form.Label className="text-danger">Gender</Form.Label>
                    <Form.Control
                      as="select"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </Form.Control>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formPhone">
                    <Form.Label className="text-danger">
                      Phone Number
                    </Form.Label>
                    <Form.Control
                      type="tel"
                      autoComplete="off"
                      placeholder="Enter your phone number"
                      name="phone"
                      className="bg-light"
                      maxLength={10}
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={6} controlId="formGst">
                    <Form.Label className="text-danger">GST Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your GST Number"
                      name="gst"
                      className="bg-light"
                      maxLength={15}
                      value={formData.gst}
                      onChange={handleChange}
                      required
                    />
                  </Form.Group>
                </Row>
                {showAlert && (
                  <Row>
                    <Col className="h5 mt-3 text-danger">{error}</Col>
                  </Row>
                )}
                <Button
                  variant="danger"
                  className="mt-4"
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <OffcanvasMenu />
    </Container>
  );
};

export default EditProfile;
