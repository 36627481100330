import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const CancellationAndRefundPolicy = () => {
  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-left">
            <h2
              className="text-center text-danger mb-4"
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Cancellation and Refund Policy
            </h2>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              This cancellation policy outlines how you can cancel or seek a
              refund for a product/service that you have purchased through
              Fusion Gaming. Under this policy:
            </p>

            <ul
              style={{
                fontSize: "1.1rem",
                paddingLeft: "1.5rem",
                marginBottom: "1.5rem",
                lineHeight: "1.8",
                color: "#fff", // Set bullet point text to white
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              <li style={{ marginBottom: "1rem" }}>
                Cancellations will only be considered if the request is made
                within <strong className="text-danger">24 hours</strong> of
                placing the order. However, cancellation requests may not be
                entertained once if the orders status has been updated to
                “Confirmed” or the product is out for delivery.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                Fusion Gaming does not accept cancellation requests for{" "}
                <strong className="text-danger">Custom-built PCs</strong>, which
                are considered perishable in the context of our product
                offerings. However, refunds or replacements may be made if the
                customer establishes that the quality of the product delivered
                is not good.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                In case of receipt of damaged or defective items, please report
                to our customer service team. The request will be entertained
                once our team has checked and determined the same at its own
                end. This should be reported within{" "}
                <strong className="text-danger">3 days</strong> of receipt of
                products. In case you feel that the product received is not as
                shown on the site or as per your expectations, you must bring it
                to the notice of our customer service within{" "}
                <strong className="text-danger">3 days</strong> of receiving the
                product. The customer service team after looking into your
                complaint will take an appropriate decision.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                In case of complaints regarding products that come with a{" "}
                <strong className="text-danger">warranty</strong> from the
                manufacturers, please refer the issue to them.
              </li>
              <li>
                In case of any refunds approved by Fusion Gaming, it will take{" "}
                <strong className="text-danger">7 working days</strong> for the
                refund to be processed to you.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CancellationAndRefundPolicy;
