import { useContext, createContext, useState } from "react";

const MyContext = createContext();

export function useAuth() {
  return useContext(MyContext);
}

export const MyProvider = ({ children }) => {
  const [authContext, setAuthContext] = useState(null);
  const [user, setUser] = useState(null);

  return (
    <MyContext.Provider
      value={{
        authContext,
        setAuthContext,
        user,
        setUser,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

export default MyContext;
