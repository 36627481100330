import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ShippingPolicy = () => {
  return (
    <div className="bg-black text-light py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8}>
            <h1
              className="text-center text-danger mb-4"
              style={{ fontSize: "2.5rem", fontWeight: "bold" }}
            >
              Shipping Policy
            </h1>
            <div className="p-4 rounded shadow-sm">
              <p className="mb-3">
                Orders from{" "}
                <strong className="text-danger">Fusion Gaming</strong> are
                shipped through registered domestic courier companies and/or
                speed post only. Orders are shipped within 2 to 5 days from the
                date of the order and/or payment or as per the delivery date
                agreed at the time of order confirmation, subject to courier
                company/post office norms.
              </p>
              <p className="mb-3">
                <strong className="text-danger">Fusion Gaming</strong> shall not
                be liable for any delay in delivery by the courier company or
                postal authority. Delivery of all orders will be made to the
                address provided by the buyer at the time of purchase.
              </p>
              <p className="mb-3">
                <span>Delivery</span> of our services will be confirmed via your
                email ID as specified during registration. Any shipping costs
                levied by Fusion Gaming are non-refundable.
              </p>
              <p>
                <span>For assistance</span> with our services, please contact
                our helpdesk at{" "}
                <a
                  href="mailto:future.retail20@gmail.com"
                  className="text-danger fw-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  future.retail20@gmail.com
                </a>
                .
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ShippingPolicy;
