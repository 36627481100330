import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./Components/Header&Footer/Header";
import Footer from "./Components/Header&Footer/Footer";
import Home from "./Components/HomePage/Home";
import Whatsapp from "./Components/Floaters/Whatsapp";
import ReturnsPolicy from "./Components/PolicyPages/ReturnsPolicy";
import ShippingPolicy from "./Components/PolicyPages/ShippingPolicy";
import TermsAndConditions from "./Components/PolicyPages/TermsAndConditions";
import CancellationAndRefundPolicy from "./Components/PolicyPages/CancellationAndRefundPolicy";
import PrivacyPolicy from "./Components/PolicyPages/PrivacyPolicy";
import Careers from "./Components/Forms/Careers";
import VideoConsultation from "./Components/Forms/VideoConsultation";
import ContactUs from "./Components/Forms/ContactUs";
import RequireAuth from "./Components/Hooks/RequireAuth";
import Login from "./Components/Forms/Login";
import { useAuth } from "./Components/Contexts/MyContext";
import { url } from "./config";
import axios from "axios";
import Register from "./Components/Forms/Register";
import ForgotPassword from "./Components/Forms/ForgotPassword";
import MyProfile from "./Components/UserDashboard/MyProfile";
import EditProfile from "./Components/UserDashboard/EditProfile";
import MyOrders from "./Components/UserDashboard/MyOrders";
import MyAddresses from "./Components/UserDashboard/MyAddresses";
import AddAddress from "./Components/UserDashboard/AddAddress";
import MyCoupons from "./Components/UserDashboard/MyCoupons";
import MyCart from "./Components/Cart&Checkout/MyCart";
import Checkout from "./Components/Cart&Checkout/Checkout";
import PaymentFail from "./Components/Payment/PaymentFail";
import PaymentSuccess from "./Components/Payment/PaymentSuccess";
import PaymentVerification from "./Components/Payment/PaymentVerification";
import Configurator from "./Components/BuildYourOwnPC/Configurator";
import PreBuiltSelector from "./Components/PreBuilts/PreBuiltSelector";
import MyTickets from "./Components/UserDashboard/MyTickets";

import { Spinner } from "react-bootstrap";
import Unauthorized from "./Components/Forms/Unauthorized";
import Accessories from "./Components/Accessories/Accessories";

axios.defaults.withCredentials = true;

const App = () => {
  const { setUser, setAuthContext } = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function reload() {
      setLoading(true);
      try {
        const res = await axios.post(url + "/authenticate", {
          withCredentials: true,
        });
        if (res.data.status !== 400) {
          setAuthContext(res.data.access_token);
          fetchUserData(res.data.user_id);
        }
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    const fetchUserData = async (uid) => {
      try {
        const res = await axios.post(
          url + "/fetchAllUserDetails",
          { id: uid },
          { withCredentials: true }
        );
        setUser(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    reload();
  }, [setAuthContext, setUser]);

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="bg-black d-flex flex-column" style={{ minHeight: "100vh" }}>
      <Header />
      <main className="flex-grow-1">
        <Whatsapp />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route
            path="/cancellation-and-refund-policy"
            element={<CancellationAndRefundPolicy />}
          />
          <Route path="/returns-policy" element={<ReturnsPolicy />} />
          <Route path="/shipping-policy" element={<ShippingPolicy />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/video-consultation" element={<VideoConsultation />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/config" element={<Configurator />} />
          <Route path="/prebuilts" element={<PreBuiltSelector />} />
          <Route path="/accessories" element={<Accessories />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route element={<RequireAuth />}>
            <Route path="/profile" element={<MyProfile />} />
            <Route path="/editprofile" element={<EditProfile />} />
            <Route path="/orders" element={<MyOrders />} />
            <Route path="/addresses" element={<MyAddresses />} />
            <Route path="/addaddress" element={<AddAddress />} />
            <Route path="/coupons" element={<MyCoupons />} />
            <Route path="/tickets" element={<MyTickets />} />
            <Route path="/cart" element={<MyCart />} />
            <Route path="/checkout" element={<Checkout />} />
          </Route>
          <Route
            path="/payment/validate/:merchantTransactionId"
            element={<PaymentVerification />}
          />
          <Route
            path="/payment/success/:merchantTransactionId"
            element={<PaymentSuccess />}
          />
          <Route
            path="/payment/fail/:merchantTransactionId"
            element={<PaymentFail />}
          />
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
};

export default App;
