import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";

const Support = ({ show, onHide, orderId, ticketId, desc }) => {
  const [issue, setIssue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { user, setUser } = useAuth();

  useEffect(() => {
    setIssue(desc);
  }, [desc]);

  const handleIssueChange = (e) => {
    setIssue(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!issue) {
      setErrorMessage("Please describe the issue!");
      return;
    }
    setIsLoading(true);
    if (ticketId === undefined) {
      raiseTicket();
    } else {
      editTicket();
    }
  };
  const raiseTicket = async () => {
    try {
      const res = await axios.post(
        url + "/support",
        { uid: user.userInfo[0].id, orderId, issue },
        { withCredentials: true }
      );
      setUser({ ...user, userTickets: res.data });
      setErrorMessage("Issue Raised!");
      setTimeout(() => {
        setErrorMessage("");
        setIssue("");
        setIsLoading(false);
        onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  const editTicket = async () => {
    try {
      const res = await axios.post(
        url + "/editTicket",
        { id: ticketId, uid: user.userInfo[0].id, desc: issue },
        {
          withCredentials: true,
        }
      );
      setUser({ ...user, userTickets: res.data });
      setErrorMessage("Ticket Edited!");
      setTimeout(() => {
        setErrorMessage("");
        setIssue("");
        setIsLoading(false);
        onHide();
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Modal.Title className="text-danger">
          {ticketId ? "Edit Ticket" : "Raise A Ticket"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: "#151515" }}>
        <span className="text-light">
          Order ID : <span className="text-danger">{orderId}</span>
        </span>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="details">
            <Form.Control
              className="mt-3"
              as="textarea"
              rows={4}
              placeholder="Tell us briefly about the issue you are facing..."
              value={issue}
              onChange={handleIssueChange}
              maxLength={300}
            />
          </Form.Group>
          {errorMessage && <p className="text-danger mt-3">{errorMessage}</p>}
          <Row className="mt-3">
            <Col className="col-xs-6 col-sm-3">
              <Button
                variant="danger"
                type="submit"
                disabled={isLoading}
                onClick={handleSubmit}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Support;
