import React from "react";

import Row1 from "./Elements/Row1";
import Row2 from "./Elements/Row2";
import Row3 from "./Elements/Row3";
import Row4 from "./Elements/Row4";
import Row5 from "./Elements/Row5";

const Home = () => {
  return (
    <div className="bg-black">
      <Row1 />
      <Row2 />
      <Row3 />
      <Row4 />
      <Row5 />
    </div>
  );
};

export default Home;
