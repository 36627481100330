import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { Link } from "react-router-dom";

import { auth } from "../Firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { url } from "../../config";
import {
  validEmail,
  validName,
  validPhone,
  validPassword,
} from "../Regex/Regex";

const Register = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    retypePassword: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!formData.firstName || !validName.test(formData.firstName)) {
      setError("Please enter a valid first name!");
      setShowAlert(true);
      return;
    }
    if (!formData.lastName || !validName.test(formData.lastName)) {
      setError("Please enter a valid last name!");
      setShowAlert(true);
      return;
    }
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      return;
    }
    if (!formData.phone || !validPhone.test(formData.phone)) {
      setError("Please enter a valid 10 digit phone number!");
      setShowAlert(true);
      return;
    }
    if (!formData.password || !validPassword.test(formData.password)) {
      setError("Please enter a valid password!");
      setShowAlert(true);
      return;
    }
    if (formData.password !== formData.retypePassword) {
      setError("Passwords do not match! Try again!");
      setShowAlert(true);
      return;
    }
    registerUser(formData);
  };

  async function registerUser(data) {
    try {
      await createUserWithEmailAndPassword(auth, data.email, data.password);
      const user = auth.currentUser;
      await sendEmailVerification(auth.currentUser);
      if (user) {
        const id = user.uid;
        await axios.post(
          url + "/register",
          {
            id,
            firstName: data.firstName,
            lastName: data.lastName,
            number: data.phone,
            email: data.email,
          },
          { withCredentials: true }
        );
        setError("User Registered Successfully!!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/login");
        }, 3000);
      }
    } catch (error) {
      if (error.message === "Firebase: Error (auth/email-already-in-use).") {
        setError("Email already in use. Try sign in!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/login");
        }, 3000);
      } else {
        setError("An error occurred during sign up");
        setShowAlert(true);
      }
    }
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              SIGN UP
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={5}>
            <h5
              style={{
                fontSize: "1.1rem",
                letterSpacing: "1px",
              }}
              className="text-center text-light mb-4"
            >
              Welcome to Fusion Gaming!!
            </h5>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formFirstName" className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your first name"
                  name="firstName"
                  className="bg-light"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formLastName" className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your last name"
                  name="lastName"
                  className="bg-light"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPhone" className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Enter your phone number"
                  name="phone"
                  className="bg-light"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  name="password"
                  value={formData.password}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formRetypePassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Retype password"
                  name="retypePassword"
                  value={formData.retypePassword}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      Your password must be minimum eight characters with at
                      least one uppercase letter, one lowercase letter, one
                      number and one special character.
                    </p>
                  </Container>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      By clicking submit, you agree to our{" "}
                      <Link className="link-danger" to="/privacy-policy">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link className="link-danger" to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>
                      .
                    </p>
                  </Container>
                </Col>
              </Row>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button variant="danger" size="md" onClick={handleSubmit}>
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Register;
