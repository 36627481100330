import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  Nav,
  NavItem,
  NavLink,
  Offcanvas,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  FaBars,
  FaUser,
  FaBox,
  FaMapMarkerAlt,
  FaTag,
  FaSignOutAlt,
} from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { BiSupport } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import Support from "./Support";

const MyTickets = () => {
  const { user, setAuthContext, setUser } = useAuth();
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [ordersWithTicket, setOrdersWithTicket] = useState([]);
  const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

  const [issueOrderId, setIssueOrderId] = useState("");
  const [issueTicketId, setIssueTicketId] = useState("");
  const [issueDesc, setIssueDesc] = useState("");
  const [showSupport, setShowSupport] = useState(false);
  const handleCloseSupport = () => {
    setShowSupport(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const updatedOrders = [];
    user.userTickets.forEach((ticket) => {
      const order = user.orderInfo.find(
        (order) => order.orderid === ticket.orderid
      );
      if (order) {
        const orderAndTicket = { order, ticket };
        updatedOrders.push(orderAndTicket);
      }
    });
    setOrdersWithTicket(updatedOrders);
  }, [user]);

  const handleLogout = async (e) => {
    setAuthContext(null);
    setUser(null);
    try {
      await axios.post(url + "/clearCookie", {
        withCredentials: true,
      });
    } catch (err) {
      console.log(err);
    }
    navigate("/");
  };

  const handleDelete = async (id, uid) => {
    try {
      const res = await axios.post(
        url + "/delTicket",
        { id, uid },
        {
          withCredentials: true,
        }
      );
      setUser({ ...user, userTickets: res.data });
    } catch (error) {
      console.log(error);
    }
  };

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const OrderCard = ({
    tickedId,
    orderId,
    imageUrl,
    orderTitle,
    orderDescription,
    ticketDescription,
  }) => {
    return (
      <Card
        className="mb-3"
        border="danger"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body style={{ fontSize: "0.9rem" }}>
          <Row>
            <Col md={3} className="mb-3">
              {/* Displaying order image */}
              <Card.Img variant="top" src={imageUrl} alt="Order image" />
            </Col>
            <Col md={9}>
              {/* Displaying order details */}
              <Card.Title className="text-light">{orderTitle}</Card.Title>
              <Card.Subtitle className="mb-2 text-secondary">
                Order ID: {orderId} <br />
              </Card.Subtitle>
              <div className="text-light">
                <span className="text-danger">Spec:</span>{" "}
                <span>{orderDescription}</span> <br />
                <Col className="mt-3 text-danger">
                  <span> {"Issue:"} </span>
                  <span className="text-light">{ticketDescription}</span>
                </Col>
                <Row>
                  <Col>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="mt-3"
                      onClick={() =>
                        handleDelete(tickedId, user.userInfo[0].id)
                      }
                    >
                      <AiFillDelete />
                    </Button>
                    <Button
                      variant="outline-danger"
                      size="sm"
                      className="mt-3 ms-3"
                      onClick={() => {
                        setIssueOrderId(orderId);
                        setIssueTicketId(tickedId);
                        setIssueDesc(ticketDescription);
                        setShowSupport(true);
                      }}
                    >
                      <CiEdit />
                    </Button>
                  </Col>
                </Row>
              </div>
              {/* Help Button */}
            </Col>
          </Row>
          <Row></Row>
        </Card.Body>
      </Card>
    );
  };
  const OffcanvasMenu = () => (
    <Offcanvas
      show={showOffcanvas}
      onHide={handleToggleOffcanvas}
      placement="start"
    >
      <Offcanvas.Header
        closeButton
        closeVariant="white"
        style={{ backgroundColor: "#151515" }}
      >
        <Offcanvas.Title className="text-danger">Menu</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: "#151515" }}>
        <Card
          border="danger"
          className="mb-3"
          style={{ backgroundColor: "#151515" }}
        >
          <Card.Body>
            <Card.Title className="text-danger">Welcome!</Card.Title>
            <Card.Text className="text-light">
              {user.userInfo[0].firstname} <br />
              {user.userInfo[0].email}
            </Card.Text>
          </Card.Body>
        </Card>
        <Nav defaultActiveKey="/home" className="flex-column">
          <NavItem>
            <NavLink as={Link} to="/profile" className="link-danger">
              <FaUser /> My Profile
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/orders" className="link-danger">
              <FaBox /> My Orders
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/addresses" className="link-danger">
              <FaMapMarkerAlt /> My Addresses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/coupons" className="link-danger">
              <FaTag /> My Coupons
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink as={Link} to="/tickets" className="link-danger">
              <BiSupport /> My Tickets
            </NavLink>
          </NavItem>
          <Nav.Item className="mt-3 mx-3">
            <Button variant="danger" onClick={handleLogout}>
              <FaSignOutAlt /> Logout
            </Button>
          </Nav.Item>
        </Nav>
      </Offcanvas.Body>
    </Offcanvas>
  );

  const Sidebar = () => (
    <>
      {" "}
      <Card
        border="danger"
        className="mb-3"
        style={{ backgroundColor: "#151515" }}
      >
        <Card.Body>
          <Card.Title className="text-danger">Welcome!</Card.Title>
          <Card.Text className="text-light">
            {user.userInfo[0].firstname} <br />
            {user.userInfo[0].email}
          </Card.Text>
        </Card.Body>
      </Card>
      <Nav defaultActiveKey="/home" className="flex-column">
        <NavItem>
          <NavLink as={Link} to="/profile" className="link-danger">
            <FaUser /> My Profile
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/orders" className="link-danger">
            <FaBox /> My Orders
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/addresses" className="link-danger">
            <FaMapMarkerAlt /> My Addresses
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/coupons" className="link-danger">
            <FaTag /> My Coupons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink as={Link} to="/tickets" className="link-danger">
            <BiSupport /> My Tickets
          </NavLink>
        </NavItem>
        <Nav.Item className="mt-3 mx-3">
          <Button variant="danger" onClick={handleLogout}>
            <FaSignOutAlt /> Logout
          </Button>
        </Nav.Item>
      </Nav>
    </>
  );
  return (
    <div>
      <Container className="mt-5">
        <Row className="mx-2">
          <Col xs={12} className="d-block d-lg-none">
            <Button
              variant="dark"
              onClick={handleToggleOffcanvas}
              aria-controls="offcanvas-sidebar"
              aria-expanded={showOffcanvas}
              className="mb-3"
            >
              <FaBars /> Menu
            </Button>
          </Col>
          <Col
            xs={12}
            className="d-block d-lg-none border border-dark rounded p-3"
            style={{ backgroundColor: "#151515", minHeight: "55vh" }}
          >
            <Row>
              <span className="h5 text-danger mb-3">My Tickets</span>{" "}
            </Row>
            {user.userTickets.length !== 0 ? (
              ordersWithTicket.map((ticket, i) => (
                <OrderCard
                  key={i}
                  orderId={ticket.order.orderid}
                  imageUrl={
                    "/cabinet/" +
                    JSON.parse(ticket.order.product).cabinetBrand +
                    "/" +
                    JSON.parse(ticket.order.product).cabinetBrand +
                    " " +
                    JSON.parse(ticket.order.product).cabinetModel +
                    ".png"
                  }
                  orderTitle={title(JSON.parse(ticket.order.product).price)}
                  orderDescription={ticket.order.productShort}
                  ticketDescription={ticket.ticket.description}
                  tickedId={ticket.ticket.id}
                />
              ))
            ) : (
              <Col>
                <Row className="text-secondary p-3">No Tickets Found!</Row>
                <Col>
                  <Button variant="outline-danger" as={Link} to="/orders">
                    Help & Support
                  </Button>
                </Col>{" "}
              </Col>
            )}
          </Col>
        </Row>
        <Row className="mx-2">
          <Col
            lg={3}
            className="d-none d-lg-block border border-dark rounded p-3"
            style={{ backgroundColor: "#151515" }}
          >
            <Sidebar />
          </Col>
          <Col
            lg={8}
            className="d-none d-lg-block border border-dark rounded p-3 mx-4"
            style={{ backgroundColor: "#151515", minHeight: "55vh" }}
          >
            <Row>
              <span className="h5 text-danger mb-3">My Tickets</span>{" "}
            </Row>
            {user.userTickets.length !== 0 ? (
              ordersWithTicket.map((ticket, i) => (
                <OrderCard
                  key={i}
                  orderId={ticket.order.orderid}
                  imageUrl={
                    "/cabinet/" +
                    JSON.parse(ticket.order.product).cabinetBrand +
                    "/" +
                    JSON.parse(ticket.order.product).cabinetBrand +
                    " " +
                    JSON.parse(ticket.order.product).cabinetModel +
                    ".png"
                  }
                  orderTitle={title(JSON.parse(ticket.order.product).price)}
                  orderDescription={ticket.order.productShort}
                  ticketDescription={ticket.ticket.description}
                  tickedId={ticket.ticket.id}
                />
              ))
            ) : (
              <Col>
                <Row className="text-secondary p-3">No Tickets Found!</Row>
                <Col>
                  <Button variant="outline-danger" as={Link} to="/orders">
                    Help & Support
                  </Button>
                </Col>{" "}
              </Col>
            )}
            <Row></Row>
          </Col>
        </Row>
        <OffcanvasMenu />
        <Support
          show={showSupport}
          onHide={handleCloseSupport}
          orderId={issueOrderId}
          ticketId={issueTicketId}
          desc={issueDesc}
        />
      </Container>
    </div>
  );
};

export default MyTickets;
