import React from "react";

const Whatsapp = () => {
  return (
    <div>
      <a
        href="https://wa.me/+916369933507"
        className="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default Whatsapp;
