import React, { useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { validEmail } from "../Regex/Regex";

const ForgotPassword = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      return;
    }
    resetPassword(formData);
  };

  async function resetPassword(data) {
    try {
      await sendPasswordResetEmail(auth, data.email);
      setError("Reset Email Sent. Kindly Check!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        navigate("/login");
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              Reset Password
            </h1>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button variant="danger" size="md" onClick={handleSubmit}>
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
