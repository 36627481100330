import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import VerifyPhone from "../UserDashboard/VerifyPhone";
import { FaCheck } from "react-icons/fa";

const Checkout = () => {
  const { user } = useAuth();
  const [cart] = useState(user.cartInfo);
  const [total, setTotal] = useState(0);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [deliveryAddress, setDeliveryAddress] = useState(null);
  const [netTotal, setNetTotal] = useState(total);
  const navigate = useNavigate();
  const [msg, setMsg] = useState("");
  const [showOtp, setShowOtp] = useState(false);

  axios.defaults.withCredentials = true;

  useEffect(() => {
    if (
      Date.now() - new Date(user.cartInfo[0].checkouttime).getTime() >
      15000
    ) {
      navigate("/cart");
    }
    var tot = 0;
    user.cartInfo.map((i) => (tot += i.updatedPrice * i.qty));
    setTotal(tot);
    setNetTotal(tot);
  }, [user.cartInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCloseOtp = () => {
    setShowOtp(false);
  };

  const activeCoupons = user.couponInfo.filter(
    (coupon) => new Date(coupon.validity) > new Date()
  );

  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  const handleSelectAddress = (address) => {
    setDeliveryAddress(
      address.addressid === deliveryAddress?.addressid ? null : address
    );
    setMsg("");
  };

  const handleSelectCoupon = (coupon) => {
    setSelectedCoupon(
      coupon.couponid === selectedCoupon?.couponid ? null : coupon
    );
    setNetTotal(total + coupon.value);
  };

  const handleAddAddress = (e) => {
    navigate("/addaddress");
  };

  const proceedToPayment = (e) => {
    if (!deliveryAddress) {
      setMsg("Please Select Delivery Address!");
      return;
    }
    if (!user.userInfo[0].number) {
      setMsg("Please Add Phone Number To Proceed!");
      return;
    }
    if (!user.userInfo[0].verified) {
      setShowOtp(true);
      return;
    }
    pay();
  };

  const pay = async () => {
    try {
      const res = await axios.post(
        url + "/pay",
        {
          userId: user.userInfo[0].id,
          amount: netTotal,
          deliveryAddress: deliveryAddress.address,
          name: user.userInfo[0].firstname + " " + user.userInfo[0].lastname,
          number: user.userInfo[0].number,
        },
        { withCredentials: true }
      );
      if (res.data.success) {
        window.location.href =
          res.data.data.instrumentResponse.redirectInfo.url;
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <Container>
      <h2 className="mb-4 text-danger">Checkout</h2>

      <Row>
        <Col lg={12} md={12} className="px-4">
          <Row
            className="mt-1 py-3 border border-dark rounded"
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <h4 className="mt-1 text-danger">Order Summary</h4>
            </Row>
            {cart.map((i) => (
              <Row key={i.itemid} lg={12} className="my-2 pe-3">
                {" "}
                <Card
                  border="light"
                  className="ms-3"
                  style={{ backgroundColor: "#151515" }}
                >
                  <Row>
                    <Col lg={2} sm={5} xs={12}>
                      <Card.Img
                        variant="top"
                        className="mt-4"
                        src={
                          i.itemType === "PC"
                            ? "/cabinet/" +
                              JSON.parse(i.item).cabinetBrand +
                              "/" +
                              JSON.parse(i.item).cabinetBrand +
                              " " +
                              JSON.parse(i.item).cabinetModel +
                              ".png"
                            : "/accessories/" + i.itemShort + ".png"
                        }
                        alt={i.title}
                      />
                    </Col>
                    <Col lg={10} sm={7} xs={12} className="mb-3">
                      <Card.Body>
                        <Card.Title className="text-danger mb-2">
                          {i.itemType === "PC"
                            ? title(i.updatedPrice)
                            : i.itemShort}
                        </Card.Title>
                        <Card.Text className="text-secondary">
                          <small className="text-light">
                            {i.itemType === "PC"
                              ? i.itemShort
                              : JSON.parse(i.item).item}
                          </small>
                        </Card.Text>
                        <ListGroup variant="flush">
                          <ListGroup.Item
                            style={{ backgroundColor: "#151515" }}
                            className="text-danger border-0"
                          >
                            Price:{" "}
                            <span className="text-light">
                              ₹
                              {new Intl.NumberFormat("en-IN").format(
                                i.updatedPrice
                              )}
                              /-
                            </span>
                          </ListGroup.Item>
                          <Row>
                            <Col className="col-11 col-lg-6">
                              <ListGroup.Item
                                style={{ backgroundColor: "#151515" }}
                                className="text-danger border-0"
                              >
                                Qty: <span className="text-light">{i.qty}</span>
                              </ListGroup.Item>
                            </Col>
                            <Col className="col-11 col-lg-6">
                              <ListGroup.Item
                                style={{ backgroundColor: "#151515" }}
                                className="text-danger border-0"
                              >
                                Subtotal:{" "}
                                <span className="text-light">
                                  ₹
                                  {new Intl.NumberFormat("en-IN").format(
                                    i.updatedPrice * i.qty
                                  )}
                                  /-
                                </span>
                              </ListGroup.Item>
                            </Col>
                          </Row>
                        </ListGroup>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </Row>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="bg-black mt-4">
        <Col lg={9} md={12}>
          <Col
            className="mb-4 px-4 border border-dark rounded"
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <h4 className="my-4 text-danger">Select Delivery Address</h4>
            </Row>
            <Row>
              {user.addressInfo.length > 0 ? (
                user.addressInfo.map((address) => (
                  <Col key={address.addressid} xs={12} sm={12} className="mb-4">
                    <Card
                      className={`selectable-card ${
                        deliveryAddress?.addressid === address.addressid
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleSelectAddress(address)}
                      style={{
                        cursor: "pointer",

                        border:
                          deliveryAddress?.addressid === address.addressid
                            ? "2px solid #dc3545"
                            : "1px solid #ddd",
                        boxShadow:
                          deliveryAddress?.addressid === address.addressid
                            ? "0 0 10px #dc3545"
                            : "none",
                        backgroundColor: "#151515",
                      }}
                    >
                      <Card.Body>
                        <Card.Title className="text-danger">
                          {address.title}{" "}
                          {deliveryAddress?.addressid === address.addressid ? (
                            <FaCheck />
                          ) : (
                            " "
                          )}
                        </Card.Title>
                        <Card.Text className="text-light">
                          {address.address}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))
              ) : (
                <Row className="text-secondary mb-3 px-4">
                  No Addresses Added!
                </Row>
              )}
            </Row>
            <Row>
              <Col className="mb-3">
                <Button variant="danger" onClick={handleAddAddress}>
                  Add a New Address
                </Button>
              </Col>
            </Row>
          </Col>
        </Col>
        <Col lg={3} md={12}>
          <Col
            className="mb-4 px-3 border border-dark rounded"
            style={{ backgroundColor: "#151515" }}
          >
            <Row>
              <h4 className="my-4 text-danger">Select Coupon</h4>
            </Row>
            {activeCoupons.length === 0 ? (
              <Row className="text-secondary px-3 mb-4">
                No Active Coupon Found!
              </Row>
            ) : (
              <Row>
                {activeCoupons.map((coupon) => (
                  <Col key={coupon.couponid} xs={12} className="mb-4">
                    <Card
                      className={`selectable-card ${
                        selectedCoupon?.couponid === coupon.couponid
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleSelectCoupon(coupon)}
                      style={{
                        cursor: "pointer",
                        border:
                          selectedCoupon?.couponid === coupon.couponid
                            ? "2px solid #dc3545"
                            : "1px solid #ddd",
                        boxShadow:
                          selectedCoupon?.couponid === coupon.couponid
                            ? "0 0 10px #dc3545"
                            : "none",
                        backgroundColor: "#151515",
                      }}
                    >
                      <Card.Body>
                        <Card.Title className="text-danger">
                          {coupon.description}{" "}
                          {selectedCoupon?.couponid === coupon.couponid ? (
                            <FaCheck />
                          ) : (
                            " "
                          )}
                        </Card.Title>
                        <Card.Text className="text-light">
                          Value: ₹
                          {coupon.value < 0
                            ? new Intl.NumberFormat("en-IN").format(
                                coupon.value * -1
                              ) + "(-)"
                            : new Intl.NumberFormat("en-IN").format(
                                coupon.value
                              ) + "(+)"}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Col>
      </Row>
      <Col className="px-3">
        <Row
          className="mt-2 border rounded border-light"
          style={{ backgroundColor: "#151515" }}
        >
          <Row>
            {deliveryAddress ? (
              <Col>
                <Col>
                  <h5 className="text-danger mt-3" style={{ fontSize: "1rem" }}>
                    Delivery :{" "}
                    <span className="text-light">
                      {user.userInfo[0].firstname +
                        " " +
                        user.userInfo[0].lastname}{" "}
                      {", "}
                      {deliveryAddress?.address}
                    </span>
                  </h5>
                </Col>
                {user.userInfo[0].number ? (
                  <Col>
                    <h5
                      className="text-danger mt-3"
                      style={{ fontSize: "1rem" }}
                    >
                      Phone :
                      <span className="text-light">
                        {" "}
                        {user.userInfo[0].number}
                      </span>
                    </h5>
                  </Col>
                ) : (
                  ""
                )}
              </Col>
            ) : (
              ""
            )}
          </Row>
          <Row className="">
            <Col>
              <h5 className="text-danger mt-3" style={{ fontSize: "1rem" }}>
                Total Before Coupon:{" "}
                <span className="text-light">
                  {" ₹ " +
                    new Intl.NumberFormat("en-IN").format(total.toFixed(0)) +
                    "/-"}
                </span>
              </h5>
            </Col>
          </Row>
          <Row>
            <Col>
              <h5 className="text-danger" style={{ fontSize: "1rem" }}>
                Coupon Value:{" "}
                <span className="text-light">
                  {selectedCoupon?.value < 0 ? "-" : ""}
                  {selectedCoupon
                    ? " ₹ " +
                      new Intl.NumberFormat("en-IN").format(
                        selectedCoupon?.value < 0
                          ? selectedCoupon.value * -1
                          : selectedCoupon.value
                      ) +
                      "/-"
                    : "Not Applicable"}
                </span>
              </h5>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col>
              <h5 className="text-danger" style={{ fontSize: "1rem" }}>
                Grand Total:{" "}
                <span className="text-light">
                  {" ₹ " +
                    new Intl.NumberFormat("en-IN").format(netTotal.toFixed(0)) +
                    "/-"}
                </span>
              </h5>
            </Col>
          </Row>
        </Row>
        <Row className="mt-3 px-3 text-danger">{msg}</Row>
        <Row className="my-4">
          <Col className="mb-3">
            <Button variant="danger" onClick={proceedToPayment}>
              Proceed to Payment
            </Button>
          </Col>
        </Row>
      </Col>
      <VerifyPhone show={showOtp} onHide={handleCloseOtp} />
    </Container>
  );
};

export default Checkout;
