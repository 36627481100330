import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import {
  FaDesktop,
  FaKeyboard,
  FaMouse,
  FaHeadphones,
  FaGamepad,
} from "react-icons/fa";

const Accessories = () => {
  const [selectedIcon, setSelectedIcon] = useState("monitor");
  const { authContext, user, setUser } = useAuth();
  const [accessories, setAccessories] = useState([]);
  const [displayAccessories, setDisplayAccessories] = useState([]);
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    const fetchAccessories = async () => {
      try {
        const res = await axios.post(url + "/fetchAccessories", {
          withCredentials: true,
        });
        const filtered = res.data.filter((item) => {
          return item.category === "monitor";
        });
        setDisplayAccessories(filtered);
        setAccessories(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAccessories();
  }, []);

  const handleClick = (category) => {
    setSelectedIcon(category);
    const filtered = accessories.filter((item) => {
      return item.category === category;
    });
    setDisplayAccessories(filtered);
  };

  const sendToCart = async (acc) => {
    if (authContext != null) {
      if (
        user.cartInfo.filter((i) => {
          return i.itemShort === acc.itemShort;
        }).length === 0
      ) {
        addToCart(acc.itemShort, acc);
      } else {
        setErrMsg("Item already in cart!");
        setTimeout(() => {
          setErrMsg("");
        }, 2000);
      }
    } else {
      setErrMsg("Please login to add to cart!");
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
    }
  };

  const addToCart = async (itemShort, item) => {
    try {
      const res = await axios.post(
        url + "/addToCart",
        {
          userid: user.userInfo[0].id,
          item: JSON.stringify(item),
          itemType: item.category,
          itemShort,
          qty: 1,
          stock: "Y",
        },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      setErrMsg("Item added to cart!");
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
    } catch (err) {
      console.log(err);
    }
  };

  const icons = [
    { category: "monitor", icon: <FaDesktop />, label: "Monitor" },
    { category: "keyboard", icon: <FaKeyboard />, label: "Keyboard" },
    { category: "mouse", icon: <FaMouse />, label: "Mouse" },
    { category: "headset", icon: <FaHeadphones />, label: "Headset" },
    { category: "simulator", icon: <FaGamepad />, label: "Simulator" },
  ];

  return (
    <Container className="my-5">
      <Row className="justify-content-center my-5">
        <Col
          lg={8}
          xs={12}
          className="border border-dark rounded"
          style={{ backgroundColor: "#151515" }}
        >
          <h1 className="my-4 text-center text-danger">Accessories</h1>
          <Row className="text-center">
            {icons.map((item) => (
              <Col key={item.category}>
                <div
                  onClick={() => handleClick(item.category)}
                  style={{
                    cursor: "pointer",
                    color: selectedIcon === item.category ? "#dc3545" : "#fff",
                    fontSize: "2rem",
                    margin: "10px 0",
                  }}
                >
                  {item.icon}
                </div>
                <div
                  style={{
                    color: selectedIcon === item.category ? "#dc3545" : "#fff",
                    fontSize: "1rem",
                  }}
                >
                  {item.label}
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>

      {displayAccessories.map((item, i) => (
        <Row key={i} className="justify-content-center">
          <Col
            lg={8}
            className="mt-2 p-3"
            style={{ backgroundColor: "#151515" }}
          >
            <Card border="danger" style={{ backgroundColor: "#151515" }}>
              <Row>
                <Col md={3} xs={12}>
                  <Card.Img
                    src={"/accessories/" + item.itemShort + ".png"}
                    alt="PC"
                    className="img-fluid"
                  />
                </Col>
                <Col md={9} xs={12}>
                  <Card.Body>
                    <Card.Title className="text-danger mb-2">
                      {item.itemShort}
                    </Card.Title>
                    <Row className="text-secondary">
                      <small
                        className="text-light"
                        style={{ fontSize: "0.95rem" }}
                      >
                        {item.item} <br />
                        Standard {item.warranty} year warranty
                      </small>
                      <Row>
                        <Col
                          className="text-danger mt-3"
                          style={{ fontSize: "1.2rem" }}
                        >
                          {"Price: ₹ " +
                            new Intl.NumberFormat("en-IN").format(
                              item.price.toFixed(0)
                            ) +
                            "/-"}{" "}
                        </Col>
                      </Row>
                    </Row>
                    <Row className="text-danger mt-1 px-1">{errMsg}</Row>
                    <Col md={12} className="mt-3">
                      <Button
                        variant="danger"
                        onClick={(e) => sendToCart(item)}
                      >
                        Add To Cart
                      </Button>
                    </Col>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default Accessories;
