import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Row2 = () => {
  return (
    <div>
      <Row className="bg-black mx-1">
        <Col
          className="text-light text-center my-4"
          style={{ fontSize: "1.2rem" }}
        >
          ✦ Safe Payments, Free Shipping & Lifetime Support ✦
        </Col>
      </Row>
    </div>
  );
};

export default Row2;
