import React, { useState } from "react";
import {
  Container,
  Tabs,
  Tab,
  Accordion,
  ListGroupItem,
  CardBody,
  ListGroup,
} from "react-bootstrap";

const FaqSection = () => {
  // Data for FAQ topics and Q&A
  const faqData = [
    {
      topic: "General",
      questions: [
        {
          question: "Do you build only Gaming PCs?",
          answer:
            "Absolutely not! At Fusion Gaming, we specialize in building all types of custom PCs, from everyday home setups to powerful TensorFlow workstations for AI and deep learning applications. Whether you're a gamer, a creative professional, or need a machine for complex workloads, our expert team is well-versed in the latest gaming titles and professional software suites. We’ll craft the perfect build tailored to your specific needs, all while staying within your budget!",
        },
        {
          question: "What are the components used in the build?",
          answer:
            "We utilize only trusted, high-quality components backed by warranties ranging from three years to a lifetime, ensuring exceptional performance and reliability. Each component is meticulously chosen to create a well-integrated system, providing you with peace of mind.",
        },
        {
          question: "How much should I pay to assemble?",
          answer:
            "At Fusion Gaming, we provide complimentary assembly of your machine—you only pay for the components. Additionally, we take pride in offering the most competitive pricing for PC builds throughout India, ensuring you receive exceptional value without compromising on performance.",
        },
        {
          question: "Do you accept bulk or corporate orders?",
          answer:
            "Yes, we accept bulk and corporate orders. Please feel free to contact us at +91 63699 33507 or future.retail20@gmail.com for any inquiries regarding bulk purchases.",
        },
      ],
    },
    {
      topic: "Orders",
      questions: [
        {
          question:
            "What is the warranty period for the PCs ordered from Fusion Gaming?",
          answer:
            "We offer warranties ranging from a minimum of three years to a lifetime on selected components, depending on the manufacturer's coverage, along with a 3-day dead-on-arrival policy. Warranty claims can be submitted directly through us or through the manufacturer. Our components are sourced from leading brands with service centers located in all major tier 1 and tier 2 cities, ensuring that you have convenient access to warranty support whenever needed.",
        },
        {
          question: "How long does it take to dispatch my order?",
          answer:
            "Once your order is placed on our website, it typically takes 48 to 72 working hours to get dispatched, depending on component availability. If any components are unavailable or require modifications to the selected configuration, one of our technicians will reach out to you for confirmation.",
        },
        {
          question: "When will I receive my order from the date of purchase?",
          answer:
            "Once your order has been successfully dispatched, delivery typically takes between 48 to 72 hours, depending on your location. A tracking ID will be provided upon dispatch, allowing you to monitor the status of your order independently.",
        },
        {
          question: "How do I modify/cancel an order? ",
          answer:
            "After placing an order on the website, a team member will contact you to confirm the order details. During this call, you may request modifications, cancellations, or ask any questions. Once your order is confirmed, cancellations or refunds may be limited or unavailable.",
        },
        {
          question:
            "What are the payment options available with Fusion Gaming?",
          answer:
            "We accept various payment methods, including NEFT, RTGS, UPI, and all major credit and debit cards. Our payment gateway is securely powered by PhonePe, ensuring complete safety for your transactions.",
        },
      ],
    },
    {
      topic: "Support",
      questions: [
        {
          question: "How do I check my order update?",
          answer:
            "You can check your order status by navigating to User Dashboard -> Orders. Once your order has been dispatched, the tracking ID will be updated here for your convenience, allowing you to easily monitor the transit status.",
        },
        {
          question: "How to raise a support request?",
          answer:
            "Please visit the Home page and navigate to the Orders section in the user dashboard and click Support tab or help section to fill in the required details. You will receive a call within 24 to 48 hours. Alternatively, you can reach us at +91 63819 44754 or email us at support@fusiongaming.in.",
        },
        {
          question: "What if my order arrives damaged?",
          answer:
            "We have a dedicated team responsible for securely packing your PC to ensure the safety of all components. We encourage you to record a video during the unboxing process; any physical damage documented in the video will be eligible for replacement upon raising a support ticket. At Fusion Gaming, we subject each PC to a series of rigorous synthetic tests to guarantee the stability and reliability of all components, minimizing the risk of dead-on-arrival issues. In the event that such issues do arise, we can replace the affected component if a support ticket is raised within three working days.",
        },
        {
          question: "How to remove the packaging and assemble the PC?",
          answer: (
            <CardBody>
              <p>
                {" "}
                We encourage you to record a video during the unpacking process
                to document any potential transit damage. Once you remove the
                bubble wrap from the outer packaging, you will find the PC
                securely packed within the cabinet box. To unpack, open the top
                cover of the carton and place it upside down on a flat surface,
                allowing the PC to remain on the surface as you lift the box
                off.
              </p>
              <ListGroup variant="flush">
                {" "}
                <p> Before using the machine, please ensure the following: </p>
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • All plastic protective stickers are removed from the glass
                side.{" "}
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • The PSU switch is turned on.
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • USB cables for accessories are connected to the rear ports of
                the CPU.
              </ListGroup>
              <ListGroup variant="flush">
                {" "}
                • The HDMI or DisplayPort cable from your monitor is connected
                to the output ports on the GPU, located at the bottom of the IO
                ports behind the CPU. If you have purchased a system with an
                integrated GPU processor, you may use the display output ports
                on the motherboard instead.{" "}
              </ListGroup>
            </CardBody>
          ),
        },
      ],
    },
  ];

  const [key, setKey] = useState("0"); // State to manage active tab key

  return (
    <div style={{ backgroundColor: "rgb(15, 15, 15)", height: "100%" }}>
      <Container className="mt-5 py-5">
        <h1 className="text-center text-danger mb-5">
          Frequently Asked Questions
        </h1>

        <Tabs
          id="faq-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)} // Set active tab
          className="mb-4"
          variant="pills"
          justify
          fill
        >
          {faqData.map((topic, index) => (
            <Tab eventKey={String(index)} title={topic.topic} key={index}>
              <Accordion defaultActiveKey="0">
                {topic.questions.map((qa, qaIndex) => (
                  <Accordion.Item eventKey={String(qaIndex)} key={qaIndex}>
                    <Accordion.Header>{qa.question}</Accordion.Header>
                    <Accordion.Body>
                      {qa.answer}
                      <ListGroupItem></ListGroupItem>
                    </Accordion.Body>
                  </Accordion.Item>
                ))}
              </Accordion>
            </Tab>
          ))}
        </Tabs>
      </Container>
    </div>
  );
};

export default FaqSection;
