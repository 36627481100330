import { useNavigate } from "react-router-dom"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { Button } from "react-bootstrap"

const Unauthorized = () => {
    const navigate = useNavigate();

    const goBack = () => navigate("/login", { replace: true });

    return (
        <Container>
        <Row className='mt-3'>
            <Col className="col-12 h1 mt-5 text-danger mb-5 text-center" data-bs-theme="dark">Pending Email Verification</Col>
            <Col className="col-12 h5 mt-5 text-light mb-5 text-center" data-bs-theme="dark">Kindly verify your Email ID before trying to login!</Col>
            <Col className="col-12 h5 mt-5 mb-5 text-center" data-bs-theme="dark">Check your inbox and click below!</Col>
            <Col className="h5 text-center text-light col-12">
            <Button variant="danger" onClick={goBack} size="sm">
                <span className="h5">LOGIN</span>
            </Button>
            </Col>
        </Row>
        </Container>
    )
}

export default Unauthorized