import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const TermsAndConditions = () => {
  return (
    <div className="bg-black text-white py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-left">
            <h2
              className="text-center text-danger mb-4"
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Terms & Conditions
            </h2>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>A.</strong> This document is an electronic record in terms
              of the Information Technology Act, 2000 and rules thereunder as
              applicable and the amended provisions pertaining to electronic
              records in various statutes as amended by the Information
              Technology Act, 2000. This electronic record is generated by a
              computer system and does not require any physical or digital
              signatures.
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>B.</strong> This document is published in accordance with
              the provisions of Rule 3 (1) of the Information Technology
              (Intermediaries guidelines) Rules, 2011 that require publishing
              the rules and regulations, privacy policy, and Terms of Use for
              access or usage of the domain name{" "}
              <strong className="text-danger">www.fusiongaming.in</strong>,
              including the related mobile site and mobile application
              (hereinafter referred to as “Platform”).
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>C.</strong> The Platform is owned by Fusion Gaming, a
              company incorporated under the Companies Act, 1956 with its
              registered office at Marina Mall, Second Floor, Egattur, Chennai,
              Tamil Nadu - 603103. (hereinafter referred to as "Platform Owner",
              “we”, “us”, “our”).
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>D.</strong> Your use of the Platform and services and
              tools are governed by the following terms and conditions ("Terms
              of Use") as applicable to the Platform including the applicable
              policies which are incorporated herein by way of reference. If You
              transact on the Platform, You shall be subject to the policies
              that are applicable to the Platform for such transaction. By mere
              use of the Platform, You shall be contacting with the Platform
              Owner and these terms and conditions including the policies
              constitute Your binding obligations, with the Platform Owner.
              These Terms of Use relate to your use of our website, goods (as
              applicable) or services (as applicable) (collectively,
              “Services”). Any terms and conditions proposed by You which are in
              addition to or which conflict with these Terms of Use are
              expressly rejected by the Platform Owner and shall be of no force
              or effect. These Terms of Use can be modified at any time without
              assigning any reason. It is your responsibility to periodically
              review these Terms of Use to stay informed of updates.
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>E.</strong> For the purpose of these Terms of Use,
              wherever the context so requires "you", “your” or "user" shall
              mean any natural or legal person who has agreed to become a
              user/buyer on the Platform.
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>F.</strong> Accessing, browsing or otherwise using the
              platform indicates your agreement to all the terms and conditions
              under these terms of use, so please read the terms of use
              carefully before proceeding.
            </p>

            <p
              style={{
                fontSize: "1.1rem",
                lineHeight: "1.8",
                marginBottom: "1.5rem",
              }}
            >
              <strong>G.</strong> The use of the Platform and/or availing of our
              Services is subject to the following Terms of Use:
            </p>
            <ul
              style={{
                fontSize: "1.1rem",
                paddingLeft: "1.5rem",
                lineHeight: "1.8",
                color: "#fff",
                padding: "1rem",
                borderRadius: "8px",
              }}
            >
              <li style={{ marginBottom: "1rem" }}>
                To access and use the Services, you agree to provide true,
                accurate, and complete information to us during and after
                registration, and you shall be responsible for all acts done
                through the use of your registered account on the Platform.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness, or suitability of the information and materials
                offered on this website or through the Services, for any
                specific purpose.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                Your use of our Services and the Platform is solely and entirely
                at your own risk and discretion for which we shall not be liable
                to you in any manner.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                The contents of the Platform and the Services are proprietary to
                us and are licensed to us.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You acknowledge that unauthorized use of the Platform and/or the
                Services may lead to action against you as per these Terms of
                Use and/or applicable laws.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You agree to pay us the charges associated with availing the
                Services.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You agree not to use the Platform and/or Services for any
                purpose that is unlawful or forbidden by these Terms.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You agree and acknowledge that the website and the Services may
                contain links to other third-party websites.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You understand that upon initiating a transaction for availing
                the Services you are entering into a legally binding contract
                with the Platform Owner.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                You shall indemnify and hold harmless Platform Owner and its
                affiliates from any claim or demand arising out of Your breach
                of these Terms.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                In no event will the Platform Owner be liable for any indirect,
                consequential, incidental, special, or punitive damages.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                The parties shall not be liable for any failure to perform an
                obligation under these Terms if performance is prevented by a
                force majeure event.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                These Terms shall be governed by and construed in accordance
                with the laws of India.
              </li>
              <li style={{ marginBottom: "1rem" }}>
                All disputes shall be subject to the exclusive jurisdiction of
                the courts in Chennai, Tamil Nadu.
              </li>
              <li>
                All concerns or communications relating to these Terms must be
                communicated to us using the email address{" "}
                <strong className="text-danger">www.fusiongaming.in</strong>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TermsAndConditions;
