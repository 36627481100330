import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { url } from "../../config";
import { useAuth } from "../Contexts/MyContext";
import { IoIosAdd } from "react-icons/io";
import { FaMinus } from "react-icons/fa6";
import { AiFillDelete } from "react-icons/ai";
import PCSpecModal from "../Modals/PCSpecModal";

const Cart = () => {
  const { user, setUser } = useAuth();
  const [cart, setCart] = useState(user.cartInfo);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const [pc, setPc] = useState();
  const [showPc, setShowPc] = useState(false);
  const handleClosePc = () => {
    setShowPc(false);
  };

  useEffect(() => {
    var tot = 0;
    const reload = () => {
      if (user.cartInfo.length > 0) updatePrice(user.cartInfo);
    };
    const updatePrice = async (cart) => {
      try {
        const res = await axios.post(
          url + "/updatePrice",
          { cart },
          { withCredentials: true }
        );
        setUser({ ...user, cartInfo: res.data });
        setCart(res.data);
        res.data.forEach((i) => {
          tot = tot + i.updatedPrice;
        });
        setTotal(tot);
      } catch (err) {
        console.log(err);
      }
    };
    reload();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function updateQty(itemid, qty) {
    try {
      const res = await axios.post(
        url + "/updateQty",
        { itemid, qty, userid: user.userInfo[0].id },
        { withCredentials: true }
      );
      var tot = 0;
      res.data.forEach((i) => (tot += i.updatedPrice * i.qty));
      setTotal(tot);
      setUser({ ...user, cartInfo: res.data });
      setCart(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  const handleSubmit = () => {
    checkout();
  };

  async function checkout() {
    try {
      const res = await axios.post(
        url + "/checkout",
        { uid: user.userInfo[0].id },
        { withCredentials: true }
      );
      setUser({ ...user, cartInfo: res.data });
      navigate("/checkout");
    } catch (err) {
      console.log(err);
    }
  }

  async function delItem(itemid) {
    try {
      const res = await axios.post(
        url + "/delItem",
        { itemid, userid: user.userInfo[0].id },
        { withCredentials: true }
      );
      var tot = 0;
      res.data.map((i) => (tot += i.updatedPrice * i.qty));
      setTotal(tot);
      setUser({ ...user, cartInfo: res.data });
      setCart(res.data);
    } catch (err) {
      console.log(err);
    }
  }
  const title = (price) => {
    if (price < 60000) {
      return "Fusion Gaming Elite PC";
    } else if (price > 59999 && price < 120000) {
      return "Fusion Gaming Pro PC";
    } else if (price > 119999) {
      return "Fusion Gaming Master PC";
    }
  };

  return (
    <Container>
      <Row>
        <Col lg={8} md={12}>
          <Container
            className="mb-4 border border-dark rounded"
            style={{
              backgroundColor: "#151515",
            }}
          >
            <h4 className=" mt-2 text-danger">My Cart</h4>
            {cart.length === 0 ? (
              <h4 className="text-secondary mt-5">No Items in Cart!</h4>
            ) : (
              cart.map((i) => (
                <Row key={i.itemid} xs={12} className="p-3">
                  {" "}
                  {/* 1 card per row */}
                  <Card border="danger" style={{ backgroundColor: "#151515" }}>
                    <Row>
                      <Col lg={4} md={5} xs={12}>
                        <Card.Img
                          variant="top"
                          className="mt-2"
                          src={
                            i.itemType === "PC"
                              ? "/cabinet/" +
                                JSON.parse(i.item).cabinetBrand +
                                "/" +
                                JSON.parse(i.item).cabinetBrand +
                                " " +
                                JSON.parse(i.item).cabinetModel +
                                ".png"
                              : "/accessories/" + i.itemShort + ".png"
                          }
                          alt={i.title}
                        />
                      </Col>
                      <Col lg={8} md={7} xs={12}>
                        <Card.Body>
                          <Card.Title className="text-danger mb-2">
                            {i.itemType === "PC"
                              ? title(i.updatedPrice)
                              : i.itemShort}
                          </Card.Title>
                          <Card.Text className="text-secondary">
                            <small className="text-light">
                              {i.itemType === "PC"
                                ? i.itemShort
                                : JSON.parse(i.item).item}
                            </small>
                          </Card.Text>
                          <ListGroup variant="flush">
                            <ListGroup.Item
                              style={{ backgroundColor: "#151515" }}
                              className="text-danger border-0"
                            >
                              Price: ₹
                              {new Intl.NumberFormat("en-IN").format(
                                i.updatedPrice
                              )}
                              /-
                              <br />
                              {i.itemType === "PC" ? (
                                <Button
                                  variant="outline-danger"
                                  className="mt-3"
                                  size="sm"
                                  onClick={() => {
                                    setShowPc(true);
                                    setPc(JSON.parse(i.item));
                                  }}
                                >
                                  PC Info
                                </Button>
                              ) : (
                                ""
                              )}
                            </ListGroup.Item>
                            <Row>
                              <Col className="col-11 col-lg-5">
                                <ListGroup.Item
                                  style={{ backgroundColor: "#151515" }}
                                  className="text-danger border-0"
                                >
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={(e) => {
                                      i.qty > 1
                                        ? updateQty(i.itemid, -1)
                                        : updateQty(i.itemid, 0);
                                    }}
                                    className="me-2"
                                  >
                                    <FaMinus />
                                  </Button>
                                  {i.qty}
                                  <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    onClick={(e) => updateQty(i.itemid, 1)}
                                    className="ms-2"
                                  >
                                    <IoIosAdd />
                                  </Button>
                                </ListGroup.Item>
                              </Col>
                              <Col className="col-11 col-lg-2">
                                <Button
                                  variant="danger"
                                  className="ms-3 mt-2"
                                  size="sm"
                                  onClick={(e) => delItem(i.itemid)}
                                >
                                  <AiFillDelete />
                                </Button>
                              </Col>
                              <Col className="col-11 col-lg-6">
                                <ListGroup.Item
                                  style={{ backgroundColor: "#151515" }}
                                  className="text-danger border-0"
                                >
                                  Subtotal:{" "}
                                  <span className="text-light">
                                    ₹
                                    {new Intl.NumberFormat("en-IN").format(
                                      i.updatedPrice * i.qty
                                    )}
                                    /-
                                  </span>
                                </ListGroup.Item>
                              </Col>
                            </Row>
                          </ListGroup>
                        </Card.Body>
                      </Col>
                    </Row>
                  </Card>
                </Row>
              ))
            )}
          </Container>
        </Col>
        {cart.length === 0 ? (
          <></>
        ) : (
          <Col lg={4} md={12}>
            <Container
              className="mb-4 border border-dark rounded p-3"
              style={{ minHeight: "20vh", backgroundColor: "#151515" }}
            >
              <h4 className="text-danger">Cart Summary</h4>
              <Card
                className="mt-4"
                border="danger"
                style={{ backgroundColor: "#151515" }}
              >
                <Card.Body className="text-light">
                  <Row className="mt-2 ">
                    <Col>
                      <span className="text-danger">Taxable Value:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          (total / 1.18).toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col>
                      <span className="text-danger">GST:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          (total - total / 1.18).toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                  <Row className="mt-2 mb-2">
                    <Col>
                      <span className="text-danger">Total:</span>{" "}
                    </Col>
                    <Col className="ms-3">
                      {" ₹ " +
                        new Intl.NumberFormat("en-IN").format(
                          total.toFixed(0)
                        ) +
                        "/-"}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Col className="mt-4">
                <Button variant="danger" onClick={handleSubmit}>
                  Checkout
                </Button>
              </Col>
            </Container>
          </Col>
        )}
      </Row>
      {pc ? <PCSpecModal show={showPc} onHide={handleClosePc} pc={pc} /> : ""}
    </Container>
  );
};

export default Cart;
