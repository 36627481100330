import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import { url } from "../../config";
import { validEmail } from "../Regex/Regex";

import {
  auth,
  GoogleAuthProvider,
  signInWithPopup,
} from "../Firebase/firebase";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { Spinner } from "react-bootstrap";
import { useAuth } from "../Contexts/MyContext";
import { FcGoogle } from "react-icons/fc";

const Login = () => {
  const { user, setUser, setAuthContext } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (!formData.email || !validEmail.test(formData.email)) {
      setError("Please enter a valid email address!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
      return;
    }
    loginUser(formData);
  };

  const handleGoogleLogin = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const displayName = user.displayName || "";
        const email = user.email || "";
        const phone = user.phoneNumber || "";
        const accessToken = result.user.accessToken;
        const refreshToken = user.refreshToken;
        const uid = user.uid;
        const [firstName, lastName] = displayName.split(" ");
        fetchUserDataGoogle(
          uid,
          firstName,
          lastName,
          phone,
          email,
          accessToken,
          refreshToken
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  async function loginUser(data) {
    try {
      await signInWithEmailAndPassword(auth, data.email, data.password);
      createRefreshToken(auth.currentUser.refreshToken, auth.currentUser.uid);
      if (auth.currentUser.emailVerified) {
        fetchUserData(auth.currentUser.uid);
        setAuthContext(auth.currentUser.accessToken);
        createRefreshToken(auth.currentUser.refreshToken, auth.currentUser.uid);
        setError("Logged In Successfully!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/");
        }, 1000);
      } else {
        await sendEmailVerification(auth.currentUser);
        setError("Email Verification Pending...");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          navigate("/unauthorized");
        }, 2000);
      }
    } catch (err) {
      console.error("Error during login:", err);
      setShowAlert(true);
      if (err.code === "auth/invalid-credential") {
        setError("Incorrect password");
      } else if (err.code === "auth/too-many-requests") {
        setError("Too many attempts. Please try again later.");
      } else {
        setError("An unexpected error occurred");
      }
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    }
  }

  const createRefreshToken = async (refreshToken, uid) => {
    try {
      await axios.post(
        url + "/createCookie",
        { refreshToken, uid },
        { withCredentials: true }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserDataGoogle = async (
    uid,
    firstName,
    lastName,
    number,
    email,
    accessToken,
    refreshToken
  ) => {
    try {
      const res = await axios.post(
        url + "/fetchAllUserDetails",
        { id: uid },
        { withCredentials: true }
      );
      if (res.data?.userInfo?.length > 0) {
        setUser(res.data);
        setAuthContext(accessToken);
        createRefreshToken(refreshToken, uid);
      } else {
        await axios.post(
          url + "/register",
          {
            id: uid,
            firstName: firstName,
            lastName: lastName,
            number: number ? number : "NULL",
            email: email,
          },
          { withCredentials: true }
        );
        const res = await axios.post(
          url + "/fetchAllUserDetails",
          { id: uid },
          { withCredentials: true }
        );
        setUser(res.data);
        setAuthContext(accessToken);
        createRefreshToken(refreshToken, uid);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchUserData = async (uid) => {
    try {
      const res = await axios.post(
        url + "/fetchAllUserDetails",
        { id: uid },
        { withCredentials: true }
      );
      setUser(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    if (user?.userInfo?.length > 0) {
      navigate(-1);
    } else {
      setLoading(false);
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) {
    return (
      <div
        className="d-flex bg-black justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="grow" variant="danger">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="bg-black text-white py-5 mt-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={5}>
            <h1
              style={{
                fontSize: "2.5rem",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              className="text-center text-danger mb-4"
            >
              LOGIN
            </h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={6}>
            <h5
              style={{
                fontSize: "1.1rem",
                letterSpacing: "1px",
              }}
              className="text-center text-light mb-4"
            >
              NEW TO FUSION GAMING?{" "}
              <Link
                to="/register"
                className="link-danger link-underline-opacity-0"
              >
                SIGN UP
              </Link>
            </h5>
            <Form onSubmit={handleSubmit} className="p-4 rounded shadow-sm">
              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  name="email"
                  className="bg-light"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formPassword" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  value={formData.password}
                  className="bg-light"
                  onChange={handleChange}
                  required
                />
              </Form.Group>
              <Row className="justify-content-center">
                <Col className="h5 mt-1 text-light col-12">
                  <Container className="text-center text-secondary">
                    <p
                      style={{
                        fontSize: "0.9rem",
                      }}
                    >
                      By clicking submit, you agree to our{" "}
                      <Link className="link-danger" to="/privacy-policy">
                        Privacy Policy
                      </Link>{" "}
                      and{" "}
                      <Link className="link-danger" to="/terms-and-conditions">
                        Terms & Conditions
                      </Link>
                      .
                    </p>
                  </Container>
                </Col>
              </Row>
              {showAlert && (
                <Row>
                  <Col className="h5 text-center mt-3 text-danger">{error}</Col>
                </Row>
              )}
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button variant="danger" size="md" onClick={handleSubmit}>
                    <span className="h5">SUBMIT</span>
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-end">
                <Col
                  className="h5 mt-1 text-center text-light col-12"
                  style={{ fontSize: "1rem" }}
                >
                  <span>Forgot Password? </span>
                  <Link
                    className="link-danger link-underline-opacity-0"
                    to="/forgotpassword"
                  >
                    Click Here
                  </Link>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  Or
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="h5 mt-3 text-center text-light col-8 col-lg-4">
                  <Button variant="outline-danger" onClick={handleGoogleLogin}>
                    <FcGoogle /> Sign in with Google
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
